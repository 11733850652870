<template>
  <div class="grid gap-4" :style="blockStyles">
    <div
      :class="{
        'markdown-render space-y-2 px-4 py-3': true,
        'col-start-1 row-start-1 text-left': side === 'left',
        'col-start-2 row-start-1 text-right': side === 'right'
      }"
    >
      <h2 v-if="content.title">
        {{ content.title }}
      </h2>
      <div
        v-if="content.content"
        v-html="content.content"
      />
    </div>
    <div
      v-if="backgroundImage"
      :class="{
        'row-end-3': true,
        'col-start-2 row-start-1': side === 'left',
        'col-start-1 row-start-1': side === 'right'
      }"
    >
      <img
        :src="backgroundImage.medium"
        :alt="content[`background_image_${item.key}_alt`] ? content[`background_image_${item.key}_alt`] : ''"
        class="w-full h-full object-cover"
      />
    </div>
    <div
      v-if="content.buttonLink"
      :class="{
        'row-start-2 flex items-center bg-blue-700 text-white px-3 py-2': true,
        'col-start-1 justify-start': side === 'left',
        'col-start-2 justify-end': side === 'right'
      }"
    >
      <div class="inline-block border-2 border-white text-sm font-bold uppercase tracking-tighter px-3 py-2">
        {{ content.buttonLabel || '(No Label)' }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  friendlyName: 'Photo/Content Block',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    content () {
      return this.item.content
    },

    side () {
      return this.content.side ? this.content.side : 'right'
    },

    width () {
      return this.content.width ? this.content.width : '33%'
    },

    backgroundImage () {
      return this.content.images[`background_image_${this.item.key}`] ? this.content.images[`background_image_${this.item.key}`] : null
    },

    blockStyles () {
      return {
        gridTemplateColumns: this.side === 'right' ? `${this.width} auto` : `auto ${this.width}`
      }
    }
  }
}
</script>
