var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid gap-4",style:(_vm.blockStyles)},[_c('div',{class:{
      'markdown-render space-y-2 px-4 py-3': true,
      'col-start-1 row-start-1 text-left': _vm.side === 'left',
      'col-start-2 row-start-1 text-right': _vm.side === 'right'
    }},[(_vm.content.title)?_c('h2',[_vm._v(" "+_vm._s(_vm.content.title)+" ")]):_vm._e(),(_vm.content.content)?_c('div',{domProps:{"innerHTML":_vm._s(_vm.content.content)}}):_vm._e()]),(_vm.backgroundImage)?_c('div',{class:{
      'row-end-3': true,
      'col-start-2 row-start-1': _vm.side === 'left',
      'col-start-1 row-start-1': _vm.side === 'right'
    }},[_c('img',{staticClass:"w-full h-full object-cover",attrs:{"src":_vm.backgroundImage.medium,"alt":_vm.content[("background_image_" + (_vm.item.key) + "_alt")] ? _vm.content[("background_image_" + (_vm.item.key) + "_alt")] : ''}})]):_vm._e(),(_vm.content.buttonLink)?_c('div',{class:{
      'row-start-2 flex items-center bg-blue-700 text-white px-3 py-2': true,
      'col-start-1 justify-start': _vm.side === 'left',
      'col-start-2 justify-end': _vm.side === 'right'
    }},[_c('div',{staticClass:"inline-block border-2 border-white text-sm font-bold uppercase tracking-tighter px-3 py-2"},[_vm._v(" "+_vm._s(_vm.content.buttonLabel || '(No Label)')+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }